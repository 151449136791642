<template>
  <b-field label="Task colour">
    <div class="control">
      <p class="input">
        <color-dot
          v-for="color in colors"
          :key="color"
          :color="color"
          :selected="color === selected"
          @click.native="setColor(color)"
        />
      </p>
    </div>
  </b-field>
</template>

<script>
import { doc, updateDoc } from "@firebase/firestore";
export default {
  name: "TaskColor",
  props: {
    taskId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      processing: false,
      colors: [
        "#ff3860",
        "#ff9b00",
        "#ffdd57",
        "#00C6CE",
        "#02A9E6",
        "#0173E5",
        "#9501E6",
        "#E501A8",
        "#222222"
      ]
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    task() {
      return this.$store.getters["tasks/task"](this.taskId) || {};
    },
    selected() {
      return this.$_.get(this.task, `colors.${this.userId}`, ``);
    }
  },
  methods: {
    setColor(color) {
      if (this.processing) return;
      this.processing = true;
      const taskRef = doc(this.$firestore, `tasks/${this.taskId}`);
      return updateDoc(taskRef, {
        [`colors.${this.userId}`]: this.selected === color ? null : color
      }).then(() => {
        this.processing = false;
        this.$toast.open({
          message: `Color updated`
        });
      });
    }
  }
};
</script>
